<script>
import getJWTConfig from '../../utils/axios';

export default {
  name: 'JWTConfigMixin',
  computed: {
    jwtConfig() {
      return getJWTConfig(this.$store.state.jwt);
    },
  },
};
</script>
