<template>
  <div>
    <v-layout
      style="background-color: #FFFFFF;height: 80px"
    >
      <p
        class="text-back"
        @click="$router.back()"
      >
        <v-icon
          color="#212121"
          small
          style="margin-left: 25px;margin-right:5px;cursor: pointer"
        >
          far fa-chevron-left
        </v-icon>
        Volver
      </p>
    </v-layout>
    <v-layout style="background-color: rgba(0,0,0,0.87);">
      <v-flex
        style="margin: 17px"
      >
        <v-avatar
          color="#781778"
          tile
          size="80"
          style="margin-left: 15px;
          margin-top: 15px;margin-bottom: 15px;border-radius: 5px;"
        >
          <JobIcon :offer="application.offer" />
        </v-avatar>
      </v-flex>
      <v-flex
        :style="$vuetify.breakpoint.xsOnly ? 'margin: 35px 0 0;' : 'margin: 45px 0 0;'"
        xs11
      >
        <p class="text-title">
          {{ application.offer.computed_title }}
        </p>
        <p class="text-area-city">
          {{ application.offer.transformed_area }} / {{ city_name }}
        </p>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <OHCMessagesList
          :messages="messages"
          :ohc="application"
        />
      </v-flex>
      <v-flex xs12>
        <NewMessageCandidate
          :ohc="application"
          @newMessage="loadOHCMessage()"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import NewMessageCandidate from '@/components/candidates/NewMessageCandidate.vue';
import OHCMessagesList from '@/components/candidates/OHCMessagesList.vue';
import JobIcon from '@/components/offers/JobIcon.vue';

export default {
  name: 'OHCNewMessageCandidate',
  components: {
    NewMessageCandidate,
    OHCMessagesList,
    JobIcon,
  },
  mixins: [
    JWTConfigMixin,
  ],
  data() {
    return {
      application: [],
      messages: [],
      city_name: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadApplications();
      this.loadOHCMessage();
    });
  },
  methods: {
    loadApplications() {
      this.$store.commit('setLoading');
      const endpoint = this.$store.state.endpoints.candidates.getOHC(this.$route.params.uuid);
      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.application = response.data;
          this.loadCityName();
          this.$store.commit('unsetLoading');
        });
    },
    loadOHCMessage() {
      const endpoint = this.$store.state.endpoints.offers.listMessage(this.$route.params.uuid);
      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.messages = response.data;
        });
    },
    loadCityName() {
      const endpoint = this.$store.state.endpoints.offers.public.cities;
      axios.get(endpoint, { params: { country: this.application.offer.country_code } })
        .then(response => {
          if (this.application.offer.city) {
            console.log(this.application.offer.city, 'CITYNAME');
            const cities = response.data;
            const city = cities.find(payload => payload.uuid === this.application.offer.city);
            this.city_name = city.name;
          }
        });
    },
  },
};
</script>

<style scoped>
.text-back {
  color: #373737;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: capitalize;
  margin-left: 10px;
  margin-top: 27px;
  cursor: pointer;
}
.text-title {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin: 0px;
}
.text-area-city {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
  padding-right: 5px;
}
</style>
