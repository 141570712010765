<template>
  <div
    :style="$vuetify.breakpoint.xsOnly
      ? 'border-top:2px solid #e6e5e7'
      : 'padding: 10px 25px 0px;border-top:1px solid #e6e5e7;margin-bottom: 30px'"
  >
    <v-layout
      row
      wrap
    >
      <div
        v-for="(file,idFile) in files"
        :key="file.uuid"
        class="files"
      >
        <v-layout justify-end>
          <v-icon
            size="10"
            style="position: absolute; margin-top: 5px;margin-right: 0px;cursor: pointer"
            @click="deleteFile(idFile)"
          >
            fal fa-times
          </v-icon>
        </v-layout>
        <v-layout
          justify-center
          style="min-width: 100px"
        >
          <v-icon
            size="25"
            color="#30B0BF"
          >
            fal fa-file-alt
          </v-icon>
          <p class="text-files">
            {{ file.name }}
          </p>
        </v-layout>
      </div>
    </v-layout>
    <v-layout
      justify-center
      :align-center="!$vuetify.breakpoint.xsOnly"
      mt-1
    >
      <v-flex
        xs10
        sm10
        md11
      >
        <div
          id="toolbar"
          :style="$vuetify.breakpoint.xsOnly
            ? 'height: 40px;border:none;margin-top: 15px'
            : 'height: 40px;border:none;'"
        >
          <button class="ql-underline" />
          <button class="ql-bold" />
          <button
            class="ql-list"
            value="bullet"
          />
          <!-- <button class="ql-link" />-->
          <button
            v-if="!$vuetify.breakpoint.xsOnly"
            class="ql-custom-button"
            @click="pickFile()"
          >
            <v-icon
              id="clip"
              small
              style="cursor: pointer;"
              :color="$vuetify.breakpoint.xsOnly ? '#d1cccc' : '#212121'"
            >
              far fa-paperclip
            </v-icon>
            <input
              id="files"
              ref="files"
              type="file"
              style="display: none; cursor: pointer;"
              accept="*"
              multiple
              @change="handleFileUploads($event)"
            >
          </button>
        </div>
        <quill-editor
          ref="myTextEditor"
          v-model="content"
          :style="$vuetify.breakpoint.xsOnly ? '' : 'height: 131px;border-radius: 10px'"
          class="editor"
          :value="content"
          :options="editorOption"
          @change="onEditorChange"
        />
      </v-flex>
      <v-flex
        xs2
        sm2
        md1
      >
        <v-layout
          v-if="!$vuetify.breakpoint.xsOnly"
          justify-center
          style="margin-top: 35px;margin-left: 5px"
        >
          <v-btn
            fab
            color="#30B0BF"
            class="elevation-0"
            :disabled="disabledButton"
            @click="sendMessage()"
          >
            <v-icon
              large
              color="#FFFFFF"
            >
              fad fa-caret-right
            </v-icon>
          </v-btn>
        </v-layout>
        <v-layout
          v-else
          justify-end
        >
          <v-btn
            style="margin: 0px;margin-top: 4px"
            icon
            flat
            @click="pickFile()"
          >
            <v-icon
              id="clip"
              small
              style="cursor: pointer;"
              :color="$vuetify.breakpoint.xsOnly ? '#d1cccc' : '#212121'"
            >
              far fa-paperclip
            </v-icon>
            <input
              id="filesResponsive"
              ref="files"
              type="file"
              style="display: none; cursor: pointer;"
              accept="*"
              multiple
              @change="handleFileUploads($event)"
            >
          </v-btn>
          <v-btn
            style="margin: 0px;margin-top: 3px"
            flat
            icon
            class="elevation-0"
            :disabled="disabledButton"
            @click="sendMessage()"
          >
            <v-icon
              large
              color="#30B0BF"
            >
              fad fa-caret-right
            </v-icon>
          </v-btn>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios';
import dedent from 'dedent';
import hljs from 'highlight.js';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'NewMessageCandidate',
  components: {
    quillEditor,
  },
  mixins: [
    JWTConfigMixin,
  ],
  props: {
    ohc: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sender: '',
      fileName: '',
      editorOption: {
        modules: {
          toolbar: '#toolbar',
          syntax: {
            highlight: text => hljs.highlightAuto(text).value,
          },
        },
        placeholder: 'Escribele a la empresa',
        theme: this.$vuetify.breakpoint.xsOnly ? 'bubble' : 'snow',
      },
      content: dedent``,
      files: [],
      filesResponsive: false,
    };
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill;
    },
    contentCode() {
      return hljs.highlightAuto(this.content).value;
    },
    disabledButton() {
      return this.files.length === 0 && this.content === dedent``;
    },
  },
  methods: {
    pickFile() {
      this.$refs.files.click();
    },
    handleFileUploads(event) {
      this.files = [...this.files, ...event.target.files];
    },
    deleteFile(idFile) {
      this.files.splice(idFile, 1);
    },
    onEditorChange(event) {
      this.filesResponsive = event.html && this.$vuetify.breakpoint.xsOnly;
    },
    sendMessage() {
      const fd = new FormData();
      fd.append('content', this.content);
      fd.append('ohc', this.ohc.uuid);
      if (!(typeof this.files === 'string')) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.files.length; i++) {
          const file = this.files[i];

          fd.append(`attachments[ ${i}]`, file);
        }
      }
      const url = `${this.$store.state.endpoints.offers.createMessage}`;
      axios.post(url, fd, this.jwtConfig)
        .then(() => {
          this.content = dedent``;
          this.files = [];
          this.$emit('newMessage', '');
        });
    },
  },
};
</script>

<style scoped>
.files {
  box-sizing: border-box;
  height: 54px;
  border: 1px solid #30B0BF;
  border-radius: 6px;
  margin: 8px;
  padding: 0px 10px 0px;
}
.text-files {
  height: 25px;
  color: #30B0BF;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.12px;
  line-height: 25px;
  text-transform: capitalize;
  margin-left: 10px;
  margin-top: 15px;
}
#clip:hover{
  color: #0066CC !important;
}
</style>
