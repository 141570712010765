<template>
  <v-icon
    medium
    color="white"
  >
    {{ jobIcon() }}
  </v-icon>
</template>

<script>
export default {
  name: 'JobIcon',
  props: {
    offer: {
      type: Array,
      required: true,
    },
  },
  methods: {
    jobIcon() {
      if (!this.offer) {
        return 'fal fa-circle-notch fa-spin';
      }
      const iconDict = {
        'Operaciones y Logística': 'fal fa-dolly',
        'Administración y Finanzas': 'fal fa-analytics',
        RRHH: 'fal fa-id-badge',
        Comercial: 'fal fa-briefcase',
        Marketing: 'fal fa-lightbulb-on',
        Tecnología: 'fal fa-phone-laptop',
        Seguridad: 'fal fa-shield-check',
      };
      return iconDict[this.offer.transformed_area]
        ? iconDict[this.offer.transformed_area]
        : 'fal fa-question-circle';
    },
  },
};
</script>
