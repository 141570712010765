<template>
  <v-layout
    row
    wrap
  >
    <v-flex
      xs12
      style="max-height: 468px;height: 468px;overflow-y: auto"
    >
      <v-layout
        v-for="(msj,index) in messages"
        ref="message_id"
        :key="msj.uuid"
        :class="`index-${index}`"
        style="padding-left: 20px;padding-bottom: 20px"
        :justify-end="msj.sender_choice === 1"
        :justify-start="msj.sender_choice === 0"
      >
        <v-flex
          v-if="msj.sender_choice === 0 && $vuetify.breakpoint.mdAndUp"
          sm1
        >
          <div
            style="margin-left:30px;margin-top:20px;height: 51px;width: 51px;
            background: #781778;border-radius: 50px;padding-left: 11px;padding-top: 12px"
          >
            <JobIcon :offer="ohc.offer" />
          </div>
        </v-flex>
        <v-flex
          xs11
          sm9
        >
          <div
            class="message-card"
            :style="msj.sender_choice === 1
              ? 'background-color: #C5F3FA'
              : 'background-color: rgba(0,0,0,0.08);margin-left:10px;'"
          >
            <div style="margin-left: 10px;">
              <v-layout style="min-height: 50px">
                <p class="text-sender">
                  {{ msj.sender }}
                </p>
                <p class="text-date-created">
                  {{ msj.creation_date }}
                </p>
                <v-icon
                  v-if="!msj.date_read"
                  small
                  style="height: 44px"
                >
                  fal fa-check
                </v-icon>
                <v-icon
                  v-else
                  small
                  style="height: 40px"
                >
                  fal fa-check-double
                </v-icon>
              </v-layout>
              <!-- eslint-disable vue/no-v-html -->
              <p
                class="text-content"
                v-html="msj.content"
              />
              <!--eslint-enable-->
              <v-layout
                row
                wrap
              >
                <div
                  v-for="attachment in msj.attachments"
                  :key="attachment.uuid"
                  class="files"
                >
                  <v-btn
                    style="width: 130px"
                    flat
                    :href="attachment.file"
                    :download="attachment.filename"
                    target="_blank"
                  >
                    <v-icon
                      size="25"
                      color="#30B0BF"
                    >
                      fal fa-file-alt
                    </v-icon>
                    <p class="text-files">
                      {{ attachment.filename }}
                    </p>
                  </v-btn>
                </div>
              </v-layout>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import JobIcon from '@/components/offers/JobIcon.vue';

export default {
  name: 'OHCMessagesList',
  components: {
    JobIcon,
  },
  mixins: [
    JWTConfigMixin,
  ],
  props: {
    messages: {
      type: Array,
      required: true,
    },
    ohc: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToElement();
    });
  },
  beforeUpdate() {
    this.$nextTick(() => {
      this.scrollToElement({ behavior: 'smooth' });
    });
  },
  methods: {
    scrollToElement(options) {
      const el = this.$el
        .getElementsByClassName(
          `index-${this.$refs.message_id.length - 1}`,
        )[0];

      if (el) {
        el.scrollIntoView(options);
      }
    },
  },
};
</script>

<style scoped>
.message-card{
  /*min-height: 212px;*/
  border-radius: 6px;
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
}
.text-sender {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin-right: 10px;
  margin-top: 4px;
}
.text-date-created{
  color: rgba(0,0,0,0.26);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
  margin-top: 11.5px;
  margin-right: 10px;
}
.text-content {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 25px;
}
.files {
  box-sizing: border-box;
  height: 54px;
  border: 1px solid #30B0BF;
  border-radius: 6px;
  margin: 8px;
}
.text-files {
  height: 25px;
  color: #30B0BF;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.12px;
  line-height: 25px;
  text-transform: capitalize;
  margin-left: 10px;
  margin-top: 20px;
}
</style>
