/**
 * Defines headers and metadata that needs to be present in axios request to authentiate it using a
 * given JWT token.
 * */
const getJWTConfig = token => {
  if (!token) {
    return {};
  } return {
    headers: {
      // Set your Authorization to 'JWT', not Bearer!!!
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
    xhrFields: {
      withCredentials: true,
    },
  };
};

export default getJWTConfig;
